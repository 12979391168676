import React from "react";

import "./Header.css";
import { motion } from "framer-motion";
import "./Header.css"
const Header = ({name}) => {
  return (  
    <motion.div className={"header"}>
    <div className="header-title">{name}</div>
  </motion.div>
  );
};
export default Header;
