import App from "./App";
import React from "react";
import AppWeb from "./website/App/App";
import ReactDOM from "react-dom/client";
import Mobile from "./screens/Mobile/Mobile";

const tg = window.Telegram.WebApp;
const root = ReactDOM.createRoot(document.getElementById("root"));

switch (tg?.platform) {
  case "ios":
    root.render(<App />);
    break;

  case "web":
    root.render(<Mobile />);
    break;

  case "weba":
    root.render(<Mobile />);
    break;

  case "android":
    root.render(<App />);
    break;

  case "tdesktop":
    root.render(<Mobile />);
    break;

  default:
    root.render(<AppWeb/>);
}
