import tonConnectUI from "./tonConnect";
import useTelegram from "./hooks/useTelegram";
import React, { createContext, useState, useEffect } from "react";

// создаем контекст
const UserContext = createContext();

// создаем провайдер контекста
export const UserProvider = ({ children }) => {
  //states
  const [ws, setWs] = useState(null);
  const [days, setDays] = useState(null);
  const [eggs, setEggs] = useState(null);
  const [user, setUser] = useState(null);
  const [boosts, setBoosts] = useState(null);
  const [userEggs, setUserEggs] = useState(null);
  const [referrals, setReferrals] = useState(null);
  const [userBasket, setUserBasket] = useState(null);
  const [userRewards, setUserRewards] = useState(null);

  //hooks
  const { userTel, tgVibro } = useTelegram();
  const userId = userTel?.id ;

  const haptic = (style) => tgVibro?.impactOccurred(style);

  async function connectWS() {
    // development
    //const newWs = new WebSocket("ws://localhost:8443");

    // production
    const newWs = new WebSocket("wss://qeggs.space/websocket");

    newWs && setWs(newWs);

    newWs.onopen = () => {
      newWs?.send(JSON.stringify({ type: "getUser", userId: userId }));
    };

    newWs.onmessage = async (event) => {
      const data = JSON.parse(event.data);

      switch (data.type) {
        case "updateUser":
          setUser(data?.user[0]);
          break;

        case "updateEggs":
          setEggs(data?.eggs);
          break;

        case "updateBoosts":
          setBoosts(data?.boosts);
          break;

        case "updateUserEggs":
          setUserEggs(data?.eggs);
          break;

        case "updateUserBasket":
          setUserBasket(data?.basket);
          break;

        case "updateDaysRewards":
          setDays(data?.days);
          break;

        case "updateUserRewards":
          setUserRewards(data?.rewards);
          break;
        case "updateReferrals":
          setReferrals(data?.referrals);
          break;
      }
    };

    newWs.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  }

  useEffect(() => connectWS(), []);

  const checkConnection = () => {
    if (ws?.readyState !== 1) {
      connectWS();
    }
  };

  return (
    <UserContext.Provider
      value={{
        ws,
        user,
        eggs,
        days,
        setWs,
        boosts,
        haptic,
        setUser,
        userEggs,
        referrals,
        connectWS,
        userBasket,
        userRewards,
        tonConnectUI,
        checkConnection,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
