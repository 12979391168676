import React, { useState, useEffect, useContext } from "react";
import "./Eggs.css";
import { motion } from "framer-motion";
import Loading from "../Loading/Loading";

import Menu from "../../components/Menu/Menu";
import Balance from "../../components/Balance/Balance";
import Header from "../../components/Header/Header";
import { getEgg } from "../../assets/img";
import EggProfile from "../../components/EggProfile/EggProfile";
import UserContext from "../../userContext";
import tonConnectUI from "../../tonConnect";
import Button from "../../website/Components/Button/Button";

const Eggs = () => {
  const [loading, setLoading] = useState(true);
  const [activeCategory, setActiveCategory] = useState("classic");
  const [selectEgg, setSelectEgg] = useState(null);
  const [showEggProfile, setShowEggProfile] = useState(false);
  const [unicCatList, setUnicCatList] = useState(null);
  const [showWallet, setShowWallet] = useState(tonConnectUI?.account?.address);
  const [visibleUnicCatEggs, setVisibleUnicCatEggs] = useState(false);
  const { user, ws, eggs, userEggs } = useContext(UserContext);
  const [activeUnicCategory, setActiveUnicCategory] = useState(null);
  useEffect(() => {
    ws?.send(JSON.stringify({ type: "getUserEggs", userId: user?.user_id }));
  }, [ws]);

  const getEggs = async (activeCategory) => {
    try {
      ws.send(JSON.stringify({ type: "getEggs", category: activeCategory }));
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  };
  useEffect(() => {
    if (activeCategory !== "own") {
      getEggs(activeCategory);
    }
  }, [activeCategory]);

  const handleSwitchCategory = (name) => {
    setActiveCategory(name);
  };
  const handleSwitchUnicCat = (name) => {
    setActiveUnicCategory(name);
  };
  const handleEggClick = (egg) => {
    setShowEggProfile(true);
    setSelectEgg(egg);
  };
  const handleCloseEggProfile = () => {
    setShowEggProfile(false);
  };
  useEffect(() => {
    ws?.send(JSON.stringify({ type: "getUserEggs", userId: user?.user_id }));
    if (eggs) {
      Promise.all(
        eggs.map((egg) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            const imgCard = new Image();

            img.src = getEgg(`${egg.name}`).img.props.src;
            imgCard.src = getEgg(`${egg.name}`).imgCard.props.src;
            img.onload = resolve;
            img.onerror = reject;
            imgCard.onload = resolve;
            imgCard.onerror = reject;
          });
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error loading images:", error);
        });
    }

    let cats = eggs
      ?.filter(
        (egg, index, self) =>
          index === self.findIndex((t) => t.unic_category === egg.unic_category)
      )
      .map((egg) => egg.unic_category);
    setUnicCatList(cats);
  }, [eggs]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={"eggs"} style={{ overflowY: "scroll" }}>
      <Header name="COLLECTION" />
      {showEggProfile && (
        <EggProfile egg={selectEgg} onClose={handleCloseEggProfile} />
      )}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={"eggs-body"}
      >
        {tonConnectUI?.account?.address && showWallet && (
          <div className="home-wallet">
            <div className="home-wallet-info">
              <div>
                {}
                <span>Wallet:</span>
                {`${tonConnectUI?.account?.address.substring(
                  0,
                  7
                )}...${tonConnectUI?.account?.address.substring(
                  tonConnectUI?.account?.address.length - 2
                )}`}
              </div>
            </div>
            <div
              className="home-wallet-disconnect"
              onClick={async () => {
                setShowWallet(false);
                await tonConnectUI.disconnect();
              }}
            >
              DISCONNECT
            </div>
          </div>
        )}
        <span className="eggs-body-balance">
          <Balance balance={user?.balance} />
        </span>

        <div className="eggs-body-categories">
          <div
            onClick={() => {
              handleSwitchCategory("own");
            }}
            className={
              activeCategory === "own"
                ? "body-categories-cat category-active"
                : "body-categories-cat"
            }
          >
            Collection
          </div>
          <div
            onClick={() => {
              handleSwitchCategory("classic");
            }}
            className={
              activeCategory === "classic"
                ? "body-categories-cat category-active"
                : "body-categories-cat"
            }
          >
            Classic
          </div>
          <div
            onClick={() => {
              handleSwitchCategory("event");
            }}
            className={
              activeCategory === "event"
                ? "body-categories-cat category-active"
                : "body-categories-cat"
            }
          >
            Event
          </div>
          <div
            onClick={() => {
              handleSwitchCategory("exclusive");
            }}
            className={
              activeCategory === "exclusive"
                ? "body-categories-cat category-active"
                : "body-categories-cat"
            }
            style={{ borderRight: 0 }}
          >
            Exclusive
          </div>
        </div>
        {activeCategory === "event" && (
          <motion.div
            className="eggs-body-collection"
            style={{ overflowY: "scroll" }}
          >
            {unicCatList &&
              unicCatList.map((cat, index) => (
                <div key={index} className="eggs-body-collection-item">
                  {cat && getEgg(cat).img}
                  <div
                    onClick={() => {
                      handleSwitchUnicCat(cat);
                      setVisibleUnicCatEggs(true);
                    }}
                    className="eggs-body-collection-img-basket"
                  >
                    {cat && cat.toUpperCase()}
                  </div>
                </div>
              ))}
          </motion.div>
        )}
        {activeCategory === "event" && visibleUnicCatEggs && (
          <div
            className="hide-button"
            onClick={() => {
              setVisibleUnicCatEggs(false);
            }}
          >
            hide
          </div>
        )}
        <motion.div
          className={
            activeCategory === "event"
              ? visibleUnicCatEggs
                ? "eggs-body-unic-collection eggs-body-unic-collection-active"
                : "eggs-body-unic-collection"
              : "eggs-body-collection"
          }
          style={{ overflowY: "scroll" }}
        >
            {activeCategory === "event" && visibleUnicCatEggs && (
          <div
            className="unic-cat-title"
          >
            {activeUnicCategory.toUpperCase()}
          </div>
        )}
          {activeCategory !== "own"
            ? eggs &&
              eggs.map((egg, index) =>
                egg.unic_category ? (
                  egg.unic_category === activeUnicCategory && (
                    <div key={index} className="eggs-body-collection-item">
                      {getEgg(egg.name).imgCard}
                      <div
                        onClick={() => {
                          handleEggClick(egg);
                        }}
                        className="eggs-body-collection-img-basket"
                      >
                        {egg.name}
                      </div>
                    </div>
                  )
                ) : (
                  <div key={index} className="eggs-body-collection-item">
                    {getEgg(egg.name).imgCard}
                    <div
                      onClick={() => {
                        handleEggClick(egg);
                      }}
                      className="eggs-body-collection-img-basket"
                    >
                      {egg.name}
                    </div>
                  </div>
                )
              )
            : userEggs &&
              userEggs
                .filter(
                  (egg, index, self) =>
                    index === self.findIndex((t) => t.name === egg.name)
                )
                .map((egg, index) => (
                  <div key={index} className="eggs-body-collection-item">
                    {getEgg(egg.name).imgCard}
                    <div
                      onClick={() => {
                        handleEggClick(egg);
                      }}
                      className="eggs-body-collection-img-basket"
                    >
                      {egg.name}
                    </div>
                  </div>
                ))}
        </motion.div>
      </motion.div>
      <Menu active={"eggs"} />
    </div>
  );
};

export default Eggs;
