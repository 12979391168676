import styles from './Egg.module.css'
import {useEffect, useRef, useState} from 'react'

import img1 from './Assets/Images/1.png'
import img2 from './Assets/Images/2.png'
import img3 from './Assets/Images/3.png'
import img4 from './Assets/Images/4.png'
import img6 from './Assets/Images/6.png'
import img7 from './Assets/Images/7.png'
// import img8 from './Assets/Images/8.png'
// import img9 from './Assets/Images/9.png'
// import img11 from './Assets/Images/11.png'
// import img15 from './Assets/Images/15.png'
// import img16 from './Assets/Images/16.png'
// import img17 from './Assets/Images/17.png'
// import img19 from './Assets/Images/19.png'

import anim11 from './Assets/Video/11.webm'
// import anim14 from './Assets/Video/14.webm'
import anim17 from './Assets/Video/17.webm'
import anim19 from './Assets/Video/19.webm'
import anim18 from './Assets/Video/18.webm'
import anim10 from './Assets/Video/10.webm'
import anim12 from './Assets/Video/12.webm'

const eggsImgs = [img1, img2, img3, img4, img6, img7]
const eggsAnims = [anim10, anim11, anim17, anim19, anim18, anim12]

const verticalOffsets = [
	0, 0.04, 0.11, 0.17, 0.26, 0.5, 0.7, 0.95, 1.15, 1.45, 1.8, 2.1, 2.2, 2.25, 2.29, 2.32, 2.32,
	2.29, 2.16, 2.0, 1.85, 1.7, 1.5, 1.3, 1.14, 0.95, 0.78, 0.6, 0.49, 0.38, 0.28, 0.18, 0.09, 0.05,
	0, 0, 0,
]

function Egg({startOffsetY = 10, startOffsetX = 0, direction, animated, imageIdx, style}) {
	const [src] = useState(
		imageIdx !== undefined && animated ? eggsAnims[imageIdx] : eggsImgs[imageIdx]
	)

	const egg = useRef()

	function rollingEgg() {
		const eggElement = egg.current

		const angleOffset = 5
		const translateOffset = angleOffset * 0.1

		let angle = Math.random() * 360 + 1

		let offsetX = 0

		setInterval(() => {
			if (document.hidden) return

			angle += angleOffset
			offsetX += translateOffset
			const cursorOffsetY = Math.floor(Math.abs((angle % 360) - 180) / 5)

			const verticalOffset = verticalOffsets[cursorOffsetY ] * 0.75

			eggElement.style.transform = `translateY(${verticalOffset}vh) translateX(${
				direction == 'right' ? offsetX : -offsetX
			}vh) rotate(${direction == 'right' ? angle : -angle}deg)`
		}, 30)
	}

	useEffect(() => {
		if (direction) rollingEgg()
	}, [])

	if (animated)
		return (
			<video
				className={styles.egg}
				loop
				muted
				autoPlay
				ref={egg}
				src={src}
				style={
					direction == 'right'
						? {bottom: `${startOffsetY}vh`, left: `${-30 + startOffsetX}%`, ...style}
						: {bottom: `${startOffsetY}vh`, right: `${-30 + startOffsetX}%`, ...style}
				}
				alt="egg"
			/>
		)
	else
		return (
			<img
				className={styles.egg}
				ref={egg}
				src={src}
				style={
					direction == 'right'
						? {bottom: `${startOffsetY}vh`, left: `${-30 + startOffsetX}%`, ...style}
						: {bottom: `${startOffsetY}vh`, right: `${-30 + startOffsetX}%`, ...style}
				}
				alt="egg"
			/>
		)
}

export default Egg
