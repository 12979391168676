import React, { useState, useEffect, useContext } from "react"
import "./Friends.css"
import { motion } from "framer-motion"

import Loading from "../Loading/Loading"
import Menu from "../../components/Menu/Menu"
import Header from "../../components/Header/Header"
import AssetsIMG from "../../assets/img"
import {useTelegram} from "../../hooks/useTelegram"
import UserContext from "../../userContext"
const { buyRewardIcon, inviteRewardIcon} =
  AssetsIMG


const images = [buyRewardIcon, inviteRewardIcon]

const Friends = () => {
  const [loading, setLoading] = useState(true)
  const [textCopied, setTextCopied] = useState(false)
  const {user, referrals, ws, connect} = useContext(UserContext)
  useEffect(() => {
    if (ws?.readyState !== 1) {
      connect();
    }
    ws?.send(JSON.stringify({type: "getReferrals", userId: user?.user_id}))

    Promise.all(
      images.map((el) => {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.src = el
          img.onload = resolve
          img.onerror = reject
        })
      })
    )
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error loading images:", error)
      })
  }, [])

  if (loading) {
    return <Loading />
  }
  return (
    <div className={"friends"}  style={{ overflowY: "scroll" }}>
      {textCopied && (
        <div className="link-copied">
          Referral link copied successfully!
        </div>
      )}
      <Header name="FRIENDS" />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={"friends-body"}
        style={{ overflowY: "scroll" }}
      >
        <motion.div
          initial={{ opacity: 0, x: "-10vw" }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="friends-text"
        >
          <div className="friends-text-title">Invite your friends!</div>
          <div className="friends-text-descr">
            You and your friend will receive bonuses!
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: "10vh" }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2 }}
          className="friends-rewards"
        >
          <div className="friends-rewards-title">REWARDS</div>
          <div className="friends-rewards-invite">
            <div>
              For each friend you both get <span>5000 eggs</span>
              <span></span>
            </div>
            <img src={inviteRewardIcon} alt="REF" />
          </div>
          <div className="friends-rewards-buy">
            <div>
            When your friend makes a purchase of a boost or egg for the first time , you
              will receive <span> 30000 eggs</span>
            </div>
            <img src={buyRewardIcon} alt="BUY" />
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: "10vh" }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          className="friends-link"
        >
          <div className="friends-link-title">PESRSONAL LINK</div>
          <div className="friends-link-href" onClick={async ()=>{
            try {

              await navigator?.clipboard.writeText(`https:t.me/qeggsbot?start=${user?.user_id ? user?.user_id: "not found user id"}`);
              setTextCopied(true)
              setTimeout(() => {
                setTextCopied(false)
              }, 5000)
            } catch (error) {
              console.error("Ошибка при копировании текста:", error);
            }
          }}>
            https:t.me/qeggsbot?start={user?.user_id ? user?.user_id: "not found user id"}
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: "10vh" }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="friends-stat"
        >
          <div className="friends-stat-title">EARNED</div>
          <div className="friends-stat-info">
            <div className="friends-stat-block">
              <div>
                TOTAL <span>{referrals?.length * 5000}</span>
              </div>
            </div>
            <div className="friends-stat-block">
              <div>
                FRIENDS <span>{referrals?.length}</span>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>

      <Menu active={"friends"} />
    </div>
  )
}

export default Friends
