import { React } from "react";
import "./CloseButton.css";
import AssetsIMG from "../../assets/img";

const { itemBG, normalEgg, CloseIcon } = AssetsIMG;
const CloseButton = ({ onClick }) => {
    
  return (
    <div
      className="closeButton"
      onClick={onClick}
      style={{
        backgroundImage: `url(${CloseIcon})`,
      }}
    ></div>
  );  
};

export default CloseButton;
