import styles from './RoadMap.module.css'
import roadmap1 from './Assets/roadmap1.png'
import roadmap2 from './Assets/roadmap2.png'
import roadmapPC from './Assets/roadmapPC.png'
import {isMobile} from 'react-device-detect'
import {ImCross} from 'react-icons/im'
import {IoIosArrowBack, IoIosArrowForward} from 'react-icons/io'
import {useEffect, useState} from 'react'

const mobileImages = [roadmap1, roadmap2]

function RoadMap({visible, closeHandler}) {
	const [imgState, setImgState] = useState(0)

	useEffect(() => {
		if (visible) setImgState(0)
	}, [visible])
	console.log("is mobile ",isMobile)
	return (
		<div className={`${styles.wrap} ${visible ? styles.visible : ''}`}>
			<img src={isMobile ? mobileImages[imgState] : roadmapPC} className={styles.img} />
			<ImCross className={styles.cross} onClick={closeHandler} />
			{isMobile && imgState == 0 && (
				<IoIosArrowForward className={styles.right} onClick={() => setImgState(1)} />
			)}
			{isMobile && imgState == 1 && (
				<IoIosArrowBack className={styles.left} onClick={() => setImgState(0)} />
			)}
		</div>
	)
}

export default RoadMap
