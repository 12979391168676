import { React, useContext, useEffect, useState } from "react";
import "./EggProfile.css";
import AssetsIMG from "../../assets/img";
import CloseButton from "../CloseButton/CloseButton";
import { getEgg } from "../../assets/img";
import UserContext from "../../userContext";
const { itemBG, tonIcon, backEgg } = AssetsIMG;

const EggProfile = ({ onClose, egg }) => {
  const [transactRes, setTransactRes] = useState(null);

  const { ws, setWs, user, userEggs, tonConnectUI, connectWS } =
    useContext(UserContext);
  const sendTon = async (amount, type) => {
    try {
      let transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 60,
        messages: [
          {
            address: "UQD_Im5FhmUtsDtl_VnFpYNM-RLGOevS6A1408TNqI0Ax4B0",
            amount: amount * 1_000_000_000,
          },
        ],
      };

      if (ws?.readyState) {
        const result = await tonConnectUI.sendTransaction(transaction);
        if (result) {
          if (ws?.readyState !== 1) {
            await connect();
            ws?.send(
              JSON.stringify({
                type: type,
                userId: user?.user_id,
                boc: result.boc,
                wallet: tonConnectUI?.account?.address,
                eggName: egg?.name,
              })
            );
          } else {
            ws?.send(
              JSON.stringify({
                type: type,
                userId: user?.user_id,
                boc: result.boc,
                wallet: tonConnectUI?.account?.address,
                eggName: egg?.name,
              })
            );
          }
        }
      }
    } catch (e) {
      console.error("Ошибка", e);
    }
  };

  const buyEgg = () => {
    if (user?.balance >= egg?.egg_cost) {
      ws?.send(
        JSON.stringify({
          type: "buyEgg",
          userId: user?.user_id,
          eggName: egg?.name,
        })
      );
    }
  };
  const chooseAsMain = () => {
    ws?.send(
      JSON.stringify({
        type: "setAsMain",
        userId: user?.user_id,
        eggName: egg?.name,
      })
    );
  };
  async function connect() {
    await connectWS();
  }
  return (
    <div className="egg-profile">
      <CloseButton onClick={onClose} />
      <div
        style={{ backgroundImage: `url(${itemBG})` }}
        className="egg-wrapper"
      >
        <div className="egg-profile-name">{egg?.name}</div>

        {egg && getEgg(`${egg?.name}`).img}
      </div>

      <div className="egg-profile-info" style={{ overflowY: "scroll" }}>
        {userEggs?.find((userEgg) => userEgg.name === egg?.name) ? (
          <div className="profile-info-buy">
            {userEggs?.find((userEgg) => userEgg.name === egg?.name) && (
              <div
                className={
                  user?.main_egg === egg.name
                    ? "profile-info-main-egg"
                    : "profile-info-has-egg"
                }
                onClick={() => {
                  chooseAsMain();
                }}
              >
                <span>
                  {user?.main_egg === egg.name ? "Main" : "Choose as main egg"}
                </span>
              </div>
            )}
          </div>
        ) : (
          egg.egg_cost !== 0 && (
            <div className="profile-info-buy">
              <div
                className={
                  user?.balance >= egg?.egg_cost
                    ? "profile-info-buy-egg"
                    : "profile-info-buy-egg not-enough"
                }
                onClick={() => {
                  buyEgg();
                }}
              >
                <span>{egg.egg_cost}</span>
                <img src={backEgg} alt="EGGS" />
              </div>

              {egg?.ton_cost !== 0 && (
                <div
                  className="profile-info-buy-ton"
                  onClick={async () => {
                    if (tonConnectUI.connected) {
                      sendTon(egg.ton_cost, "BuyEggByTon");
                    } else {
                      await tonConnectUI.openSingleWalletModal("tonkeeper");
                    } 
                  }}
                >
                  <span>{egg.ton_cost}</span>
                  <img src={tonIcon} alt="TON" />
                </div>
              )}

              {userEggs?.find((userEgg) => userEgg.name === egg?.name) && (
                <div
                  className={
                    user?.main_egg === egg.name
                      ? "profile-info-main-egg"
                      : "profile-info-has-egg"
                  }
                  onClick={() => {
                    chooseAsMain();
                  }}
                >
                  <span>
                    {user?.main_egg === egg.name
                      ? "Main"
                      : "Choose as main egg"}
                  </span>
                </div>
              )}
            </div>
          )
        )}

        <div className="profile-info-boosts">
          {egg?.name !== "Hidden" && (
            <div className="profile-info-boost">
              <span>EGGS IN BASKET PER HOUR</span> {egg?.basket_boost}
            </div>
          )}
          {egg?.name !== "Hidden" && (
            <div className="profile-info-boost">
              <span>ENERGY BOOST</span> {egg?.energyBoost}
            </div>
          )}
        </div>
        <div className="profile-info-descr">{egg?.descr}</div>
      </div>
    </div>
  );
};

export default EggProfile;
