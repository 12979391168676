import React from "react";
import "./Balance.css";
import { motion } from "framer-motion";

const Balance = ({balance }) => {
  return (
    <motion.div
      className="balance"
      initial={{ opacity: 0, x: "-10vw" }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className={"balance-title"}>
        {Number(balance).toFixed(2)}
      </div>
    </motion.div>
  );
};
export default Balance;
