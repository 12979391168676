import "./Rewards.css";
import { motion } from "framer-motion";
import AssetsIMG from "../../assets/img";
import Loading from "../Loading/Loading";
import UserContext from "../../userContext";
import tonConnectUI from "../../tonConnect";
import Menu from "../../components/Menu/Menu";
import Modal from "../../components/Modal/Modal";
import Header from "../../components/Header/Header";
import React, { useState, useEffect, useContext } from "react";
import TelegramReward from "../../components/TelegramReward/TelegramReward";

const {
  tiktok,
  calendar,
  walletIcon,
  twitterIcon,
  friendsIcon,
  telegramIcon,
  completedIcon,
  transactionIcon,
} = AssetsIMG;

const images = [
  tiktok,
  calendar,
  friendsIcon,
  twitterIcon,
  telegramIcon,
  completedIcon,
];

const Rewards = () => {
  const [loading, setLoading] = useState(true);
  const [activeDay, setActiveDay] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [rewardData, setRewardData] = useState(null);
  const [rewardView, setRewardView] = useState(null);
  const [dayRewardStatus, setDayRewardStatus] = useState(null);
  const { days, ws, user, connectWS } = useContext(UserContext);
  
  const handleDay = (day) => setActiveDay(day);
  const connect = async () => await connectWS();
  const handleModalTap = () => setShowModal(false);
  
  const getRewardsDays = async () => {
    try {
      ws?.send(JSON.stringify({ type: "getRewardsDays" }));
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  };

  useEffect(() => {
    if (ws?.readyState !== 1) {
      connect();
    }
    getRewardsDays();

    Promise.all(
      images.map((el) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = el;
          img.onload = resolve;
          img.onerror = reject;
        });
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      });
  }, []);

  useEffect(() => {
    handleDay(user?.days_in_row);
    setDayRewardStatus(user?.day_rewards_received);
  }, [user]);

  if (loading) return <Loading />;

  return (
    <div className={"rewards"} style={{ overflowY: "scroll" }}>
      {rewardView}
      {showModal && <Modal onClose={handleModalTap} data={rewardData} />}

      <Header name="REWARDS" />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={"rewards-body"}
        style={{ overflowY: "scroll" }}
      >
        <motion.div className="rewards-logo">
          <img src={calendar} alt="img" />
        </motion.div>

        <motion.div className="rewards-title">Earn more coins</motion.div>

        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2 }}
          className="rewards-calendar"
        >
          {days &&
            days?.map((day, index) => (
              <div
                key={index}
                className={
                  activeDay === index + 1
                    ? "rewards-calendar-day active-day rewards-task-img-logo"
                    : "rewards-calendar-day"
                }
              >
                Day <span>{day?.day_in_row}</span>
              </div>
            ))}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          className="rewards-daily"
        >
          Daily reward{" "}
          <span>
            {user?.days_in_row && days && days[user?.days_in_row - 1].reward}
          </span>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className={dayRewardStatus ? "rewards-claim-active" : "rewards-claim"}
          onClick={async () => {
            ws?.send(
              JSON.stringify({ type: "claimDayReward", userId: user?.user_id })
            );
          }}
        >
          Claim
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="rewards-tasks"
        >
          <div className="rewards-task">
            <div className="rewards-task-img rewards-task-img-logo">
              <img
                src={transactionIcon}
                alt="FRI_IMG"
                onClick={() => {
                  setShowModal(true);
                  setRewardData({
                    logo: transactionIcon,
                    title: "BUY AN EGG",
                    topic: "Buy your first egg  ",
                    descr: "50 000 EGGS",
                    checkButton: "CLAIM",
                    claimStatus: !user?.reward_first_buy && !user?.first_buy,
                    claimFunction: () => {
                      ws?.send(
                        JSON.stringify({
                          type: "claimFirstBuyReward",
                          userId: user?.user_id,
                        })
                      );
                    },
                  });
                }}
              />
            </div>
          </div>

          <div className="rewards-task">
            <div className="rewards-task-img rewards-task-img-logo">
              <img
                src={walletIcon}
                alt="FRI_IMG"
                onClick={() => {
                  setShowModal(true);
                  setRewardData({
                    logo: walletIcon,
                    title: "WALLET",
                    topic: "Connect your wallet  ",
                    descr: "10 000 EGGS",
                    connectButton: "CONNECT",
                    checkButton: "CLAIM",
                    claimStatus:
                      tonConnectUI?.account?.address && !user?.reward_wallet,
                    claimFunction: () => {
                      ws?.send(
                        JSON.stringify({
                          type: "claimWalletReward",
                          userId: user?.user_id,
                          wallet: tonConnectUI?.account?.address,
                        })
                      );
                    },
                  });
                }}
              />
            </div>
          </div>

          <div className="rewards-task">
            <div className="rewards-task-img rewards-task-img-logo">
              <img
                src={telegramIcon}
                alt="TEL_IMG"
                onClick={() => {
                  setRewardView(
                    <TelegramReward
                      closeView={() => {
                        setRewardView(null);
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="rewards-task">
            <div className="rewards-task-img rewards-task-img-logo">
              <img
                src={twitterIcon}
                alt="TEL_IMG"
                onClick={() => {
                  setShowModal(true);
                  setRewardData({
                    logo: twitterIcon,
                    title: "Twitter",
                    topic: "Follow us on Twitter and repost the pinned post  ",
                    descr: "5000 EGGS",
                    links: [
                      { link: "https://x.com/EggsQuantu58870", text: "X.com" },
                    ],
                    inputText: "Link to your profile",
                    inputSend: (link) => {
                      ws?.send(
                        JSON.stringify({
                          type: "setTwitterProfile",
                          userId: user?.user_id,
                          link: link,
                        })
                      );
                    },
                    inputStatus: user?.twitter_profile,
                    checkButton: "CLAIM",
                    claimStatus: !user?.reward_twitter && user?.twitter_profile,
                    claimFunction: () => {
                      ws?.send(
                        JSON.stringify({
                          type: "claimTwitterReward",
                          userId: user?.user_id,
                        })
                      );
                    },
                  });
                }}
              />
            </div>
          </div>
        </motion.div>
      </motion.div>

      <Menu active={"rewards"} />
    </div>
  );
};

export default Rewards;
