import "./App.css";
import { useEffect, useState } from "react";
import useTelegram from "./hooks/useTelegram";
import Home from "./screens/Home/Home";
import Eggs from "./screens/Eggs/Eggs";
import Preview from "./screens/Preview/Preview";
import Boost from "./screens/Boost/Boost";
import Friends from "./screens/Friends/Friends";
import { Route, Routes, MemoryRouter } from "react-router-dom";
import Rewards from "./screens/Rewards/Rewards";
import { UserProvider } from "./userContext";
import Partners from "./screens/Partners/Partners";

const tg = window.Telegram.WebApp;

function App() {
  useEffect(() => {
    tg.ready();
    tg.expand();
    tg.setHeaderColor("#050338");
  }, []);
  //        <Route index element={<Preview />}></Route>
  return (
    <UserProvider>
      <div className="App">
        <MemoryRouter>
          <Routes>
            <Route
              path="/privacy-policy"
              component={() => {
                window.location.href = "qeggs.space";
                return null;
              }}
            />
            <Route index element={<Preview />}></Route>
            <Route path={"home"} element={<Home />}></Route>
            <Route path={"eggs"} element={<Eggs />}></Route>
            <Route path={"boost"} element={<Boost />}></Route>
            <Route path={"friends"} element={<Friends />}></Route>
            <Route path={"rewards"} element={<Rewards />}></Route>
            <Route path={"partners"} element={<Partners />}></Route>
          </Routes>
        </MemoryRouter>
      </div>
    </UserProvider>
  );
}

export default App;
