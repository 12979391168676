import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import AssetsIMG from "../../assets/img";
import Loading from "../Loading/Loading";
import Menu from "../../components/Menu/Menu";
import Balance from "../../components/Balance/Balance";
import Header from "../../components/Header/Header";
import { useTelegram } from "../../hooks/useTelegram";
import vibrateDevice from "../../hooks/vibrateDevice";
import UserContext from "../../userContext";
import { getEgg } from "../../assets/img";
import "./Home.css";

const {mainBGday, mainBGnight } = AssetsIMG;
const images = [mainBGday, mainBGnight];

const Home = () => {
  const [progress, setProgress] = useState(0);
  const [tapPosition, setTapPosition] = useState({ x: 0, y: 0 });
  const [tapStyle, setTapStyle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [scoreAnimations, setScoreAnimations] = useState([]);
  const [zoomStyle, setZoomStyle] = useState(false);
  const { user, ws, userBasket, connectWS, haptic, checkConnection } =
    useContext(UserContext);
  const [balance, setBalance] = useState(user?.balance);
  const [BG, setBG] = useState(null)
  setInterval(() => {
    checkConnection();
  }, 1000);
  const handleZoom = () => {
    setZoomStyle(true);

    wait(500).then(() => {
      setZoomStyle(false);
    });
  };
  const mainEggProps = {
    onTouchStart: (event) => {
      handleEggTap(event);
    },
    className: tapStyle ? "egg-tap" : "home-body-egg",
  };

  function wait(timeout) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  }
  async function connect() {
    await connectWS();
  }
  const removeAnimation = () => {
    setScoreAnimations([]);
  };

  const increaseBalanceAnim = () => {
    let currentBalance = balance;
    const targetBalance = user?.balance;

    const interval = setInterval(() => {
      if (currentBalance < targetBalance) {
        let diff = targetBalance - currentBalance;
        let rate = Math.max(Math.round(diff * 0.1), 1);
        currentBalance = Number(currentBalance) + Number(rate);
        setBalance(currentBalance);
      } else {
        clearInterval(interval);
      }
    }, 10);
  };

  const getScore = (value) => {
    return (
      <motion.div
        initial={{ opacity: 1, y: tapPosition.y, x: tapPosition.x }}
        animate={{ opacity: 0, y: -tapPosition.y - 50 }}
        /*onAnimationComplete={() => {
          removeAnimation()
        }}*/
        transition={{ duration: 3 }}
        className="score-animation"
        style={{ position: "absolute", left: 0, top: 0 }}
      >
        {value}
      </motion.div>
    );
  };
  useEffect(() => {
    setProgress((user?.energy / user?.max_energy) * 100);
    ws.send(JSON.stringify({ type: "getUserBasket", userId: user?.user_id }));

    increaseBalanceAnim();
  }, [user]);

  useEffect(() => {
    setBG((new Date().getHours() >= 6) && (new Date().getHours() < 18) ?  mainBGday : mainBGnight)

    if (ws?.readyState !== 1) {
      connect();
    }

    Promise.all(
      images.map((el) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = el;
          img.onload = resolve;
          img.onerror = reject;
        });
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      });
  }, []);

  if (loading || !user) {
    return <Loading />;
  }
  const mainEgg = user && getEgg(`${user?.main_egg}`, mainEggProps).img;

  const handleEggTap = (event) => {
    ws?.send(JSON.stringify({ type: "tap", userId: user?.user_id }));
    if (user?.energy >= user?.tap) {
      vibrateDevice("soft");
      setTapStyle(true);
      setZoomStyle(true);
      wait(300).then(() => {
        setTapStyle(false);
        setZoomStyle(false);
      });

      const maxWidth = event.view.innerWidth;
      let tapX = event.touches[0].clientX - event.target.x;
      if (tapX > maxWidth * 0.6) {
        tapX -= maxWidth * 0.2;
      }
      const tapY = event.touches[0].clientY - event.target.y;

      setTapPosition({ x: tapX, y: tapY });
      const newScoreAnimation = getScore(user.tap);
      setScoreAnimations([...scoreAnimations, newScoreAnimation]);
    }
  };
  return (
    <div className={"home"} style={{backgroundImage: `url(${BG})`}}>
      <Header name={"Quantum eggs"} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={"home-body"}
      >
        <motion.div
          initial={{ opacity: 0, y: "-10vh" }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className={"home-header"}
        >
          <div className="home-header-block">
            <div className="header-block-title">PER TAP</div>
            <hr className="header-block-hr" style={{marginTop: "3%", marginBottom: "3%"}}/>
            <span className="header-block-per-tap">{user.tap}</span>
          </div>
       
          <div
            className="home-header-block  scale-anim-class"
            style={{backgroundColor: "#385D9C"}}
            onClick={() => {
              ws?.send(
                JSON.stringify({ type: "takeBasket", userId: user?.user_id })
              );
              ws?.send(
                JSON.stringify({ type: "getUser", userId: user?.user_id })
              );
            }}
          >
            <div className="header-block-title">BASKET</div>
            <hr className="header-block-hr" style={{marginTop: "3%", marginBottom: "3%"}}/>

            <span className="header-block-per-hour">
              {userBasket && userBasket[0]?.basket_size}
            </span>
          </div>
          <div className="home-header-block">
            <div className="header-block-title">PER HOUR</div>
            <hr className="header-block-hr" style={{marginTop: "3%", marginBottom: "3%"}}/>
            <span className="header-block-per-tap">{user.basket_max}</span>
          </div>
         
        </motion.div>

        <Header name={<Balance balance={balance} />} />

        <motion.div className="egg-container">
          {scoreAnimations.map((animation, index) => (
            <React.Fragment key={index}>{animation}</React.Fragment>
          ))}
          {mainEgg}
        </motion.div>
        <motion.div
          className={
            zoomStyle ? "home-body-progress zoom-class" : "home-body-progress"
          }
          initial={{ opacity: 0, x: "-10vw" }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div
            className={"progress-bar-fill"}
            style={{
              width: `${progress}%`,
              height: progress < 2 ? `${progress + 80}%` : "100%",
            }}
          ></div>
          <div className="progress-bar-label">
            {user.energy}/{user.max_energy}
          </div>
        </motion.div>
      </motion.div>

      <Menu active={"home"} />
    </div>
  );
};

export default Home;
