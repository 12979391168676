import React, { useState, useEffect, useContext } from "react";
import CloseButton from "../CloseButton/CloseButton";
import "./Modal.css";
import tonConnectUI from "../../tonConnect";
import UserContext from "../../userContext";
const Modal = ({ onClose, data }) => {
  const { ws, user } = useContext(UserContext);
  const [claimedStyle, setClaimedStyle] = useState(data?.claimStatus);
  const [inputStyle, setInputStyle] = useState(data?.inputStatus);
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const switchStyle = () => {
    if (claimedStyle) {
      setClaimedStyle(false);
    }
  };
  return (
    <div className="modal">
      <CloseButton onClick={onClose} />
      <div className="modal-title">{data?.title}</div>

      <div className="modal-logo">
        <img src={data?.logo} alt="logo" />
      </div>

      <div className="modal-info">
        <div className="modal-topic">{data?.topic}</div>
        <div className="modal-descr">{data?.descr}</div>
        {data?.links &&
          data?.links.map((link, index) => (
            <a key={index} href={link.link} className="modal-link">
              {link.text}
            </a>
          ))}
        {data?.inputText &&
          (inputStyle === "" && data?.inputStatus === "" ? (
            <div className="modal-form">
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  data?.inputSend(inputValue);
                  setInputStyle("true");
                }}
              >
                <label>
                  {data?.inputText}:
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </label>
                <button type="submit">Confirm</button>
              </form>
            </div>
          ) : (
            <div className="modal-descr">
              Your profile link <br/>
              <span>{data?.inputStatus || inputValue }</span>
            </div>
          ))}
        {(data?.connectButton  && !tonConnectUI?.account?.address) && (
          <div className="modal-button" onClick={async ()=>{
            await tonConnectUI.openSingleWalletModal('tonkeeper');
          }}>{data?.connectButton}</div>
        )}
        {(data?.checkButton) &&
          ((claimedStyle) ? (
            <div
              className="modal-button"
              onClick={() => {
                data?.claimFunction();  
               
                switchStyle();
              }}
            >
              {data?.checkButton}
            </div>
          ) : (
            <div className="modal-button-claimed">{data?.checkButton}</div>
          ))}
      </div>
    </div>
  );
};

export default Modal;
