import backEgg from "./back.png";
import backgroundGif from "./backgroundGif.gif";
import beatenEgg from "./beaten.png";

import gate from "./gate.png";
import hammerEgg from "./hammer.png";

import eggsIcon from "./icons/eggs.png";
import boostIcon from "./icons/boost.png";
import homeIcon from "./icons/home.png";
import friendsIcon from "./icons/friends.png";
import rewardIcon from "./icons/reward.png";
import CloseIcon from "./icons/closeButton1.png";
import itemBG from "./CollectionScreen/ItemBG.png";

import inviteRewardIcon from "./icons/referal.png";
import buyRewardIcon from "./icons/money.png";
import earnedIcon from "./icons/earned.png";

import twitterIcon from "./icons/twitter.png";
import telegramIcon from "./icons/telegram.png";
import completedIcon from "./icons/approved.png";
import walletIcon from "./icons/wallet.png";
import transactionIcon from "./icons/transaction.png";
import tonIcon from "./icons/ton.png"
import boostRocket from "./rocket.png"
import calendar from "./icons/calendar.png"
import tiktok from "./icons/tik-tok.png"
import partners from "./partners.png"
import collab from "./collaboration.png"
import tonAllNews from "./partners/tonNewKeeper.jpg"
import tapOrNot from "./partners/tapOrNot.jpg"
import tonNews from "./partners/tonNews.jpg"
import cryptoEpoch from "./partners/cryptoEpoch.jpg"
import profitHunters from "./partners/profitHunters.jpg"
import linkIcon from "./icons/link.png"
import loadingGIF from "./icons/loading.png"
import proTonNews from "./partners/proTonNews.jpg"
import qeggsLogo from "./our/QE.jpg"
import qeggsNftLogo from "./our/QeggsNFT.jpg"
import OTLogo from "./our/OT.jpg"
import mainBGday from "./mainBGday.jpg"
import mainBGnight from "./mainBGnight.jpg"

const AssetsIMG = {
  mainBGday,
  mainBGnight,
  OTLogo,
  qeggsLogo,
  qeggsNftLogo,
  proTonNews,
  loadingGIF,
  linkIcon,
  cryptoEpoch,
  profitHunters,
  tapOrNot,
  tonAllNews,
  tonNews,
  collab,
  completedIcon,
  boostRocket,
  tonIcon,
  calendar,
  eggsIcon,
  boostIcon,
  homeIcon,
  friendsIcon,
  rewardIcon,
  tiktok,
  backEgg,
  backgroundGif,
  beatenEgg,
  partners,
  gate,
  hammerEgg,

  itemBG,
  CloseIcon,

  inviteRewardIcon,
  buyRewardIcon,
  earnedIcon,

  twitterIcon,
  telegramIcon,
  walletIcon,
  transactionIcon,
};
function mergeImgProps(oldProps, additionalProps) {
  return { ...oldProps, ...additionalProps };
}

export function getEgg(index, props) {
  if (index){
    if (props) {
      const imgOld = <img src={require(`./eggs/${index}.png`)} alt="Egg" />;
      const newImgProps = mergeImgProps(props, imgOld.props);
      return {
        img: <img {...newImgProps} />,
        imgCard: <img src={require(`./eggs-card/${index}.png`)} alt="Egg" />,
      };
    }
    return {
      img: <img src={require(`./eggs/${index}.png`)} alt="Egg" />,
      imgCard: <img src={require(`./eggs-card/${index}.png`)} alt="Egg" />,
    };
  }
 
}
export default AssetsIMG;
