// import {useEffect, useState} from 'react'
import Egg from "../Components/Egg/Egg";
import Button from "../Components/Button/Button";
import background from "../Assets/background.jpg";
import backmobile from "../Assets/backmobile.jpg";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useState } from "react";
import RoadMap from "../Components/RoadMap/RoadMap";

const slogans = ["SECURITY", "CRYPTO FUTURE", "INNOVATION"];
// const startTime = new Date(1714910400000)

function AppWeb() {
  const [roadmap, setRoadmap] = useState();

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "100dvh",
        }}
      >
        <img
          src={isMobile ? backmobile : background}
          style={{
            position: "absolute",
            minWidth: "100vw",
            width: "auto",
            height: "100dvh",
            minHeight: "100dvh",
            maxHeight: "100dvh",
            aspectRatio: isMobile ? 9 / 16 : 16 / 9,
          }}
        />

        <RoadMap visible={roadmap} closeHandler={() => setRoadmap(false)} />

        <h1
          style={{
            position: "absolute",
            top: isMobile ? "30dvh" : "11dvh",
            marginLeft: isMobile ? "1.5vh" : "10vh",
            width: "100%",
            textAlign: "center",
            userSelect: "none",
            fontSize: isMobile ? "5vh" : "8vh",
          }}
        >
          {slogans[0]}
        </h1>
        <h1
          style={{
            position: "absolute",
            bottom: isMobile ? "53.5dvh" : "58dvh",
            left: isMobile ? "" : "3vh",
            width: "100%",
            textAlign: "center",
            userSelect: "none",
            textWrap: "nowrap",
            fontSize: isMobile ? "5vh" : "8vh",
          }}
        >
          {slogans[1]}
        </h1>
        <h1
          style={{
            position: "absolute",
            bottom: isMobile ? "31dvh" : "14dvh",
            left: isMobile ? "1vh" : "5vh",
            width: "100%",
            textAlign: "center",
            userSelect: "none",
            transform: isMobile ? "" : "rotate(1deg)",
            fontSize: isMobile ? "5vh" : "8vh",
          }}
        >
          {slogans[2]}
        </h1>

        <BrowserView
          style={{
            position: "absolute",
            minWidth: "100vw",
            width: "auto",
            height: "100vh",
            aspectRatio: 16 / 9,
            clipPath:
              "polygon(0 0, 40% 12%, 100% 10.3%, 80% 24.7%, 70% 25.5%, 60% 25.5%, 40% 25.5%, 0 21.5%)",
          }}
        >
          <Egg
            startOffsetY={69}
            startOffsetX={95}
            imageIdx={0}
            style={{ scale: 1.7, transform: "rotate(1deg)" }}
          />
          <Egg
            startOffsetY={69}
            startOffsetX={87}
            imageIdx={5}
            style={{ scale: 1.6, transform: "rotate(-8deg)" }}
          />
          <Egg
            startOffsetY={64}
            startOffsetX={61}
            imageIdx={1}
            style={{ scale: 2.1, transform: "rotate(3deg)" }}
          />
          <Egg
            startOffsetY={67}
            startOffsetX={67}
            imageIdx={4}
            style={{ scale: 1.9, transform: "rotate(5deg)" }}
          />
          <Egg
            startOffsetY={67}
            startOffsetX={54}
            imageIdx={3}
            style={{ scale: 1.5, transform: "rotate(-7deg)" }}
          />
          <Egg
            startOffsetY={69}
            startOffsetX={78}
            imageIdx={2}
            style={{ scale: 2, transform: "rotate(2deg)" }}
          />
        </BrowserView>

        <MobileView
          style={{
            position: "absolute",
            width: "auto",
            height: "100dvh",
            aspectRatio: 16 / 9,
            transform: "scale(0.525) translateX(-10px)",
            clipPath: isMobile
              ? "polygon(0 0, 100% 0%, 80% 24.7%, 70% 25.5%, 60% 25.5%, 40% 25.5%, 0 21.5%)"
              : "polygon(0 0, 40% 12%, 100% 10.3%, 80% 24.7%, 70% 25.5%, 60% 25.5%, 40% 25.5%, 0 21.5%)",
          }}
        >
          <Egg
            startOffsetY={69}
            startOffsetX={95}
            imageIdx={0}
            style={{ scale: 1.7, transform: "rotate(1deg)" }}
          />
          <Egg
            startOffsetY={69}
            startOffsetX={87}
            imageIdx={5}
            style={{ scale: 1.6, transform: "rotate(-8deg)" }}
          />
          <Egg
            startOffsetY={64}
            startOffsetX={61}
            imageIdx={1}
            style={{ scale: 2.1, transform: "rotate(3deg)" }}
          />
          <Egg
            startOffsetY={67}
            startOffsetX={67}
            imageIdx={4}
            style={{ scale: 1.9, transform: "rotate(5deg)" }}
          />
          <Egg
            startOffsetY={67}
            startOffsetX={54}
            imageIdx={3}
            style={{ scale: 1.5, transform: "rotate(-7deg)" }}
          />
          <Egg
            startOffsetY={69}
            startOffsetX={78}
            imageIdx={2}
            style={{ scale: 2, transform: "rotate(2deg)" }}
          />
        </MobileView>

        <BrowserView
          style={{
            position: "absolute",
            minWidth: "100vw",
            width: "auto",
            height: "100vh",
            aspectRatio: 16 / 9,
            clipPath:
              "polygon(0 0, 100% 0, 100% 50%, 80% 67%, 70% 68%, 60% 68%, 40% 68%, 0 64%)",
          }}
        >
          <Egg
            startOffsetY={27}
            startOffsetX={93}
            animated
            imageIdx={0}
            style={{ scale: 2.5 }}
          />
          <Egg
            startOffsetY={27}
            startOffsetX={84}
            animated
            imageIdx={2}
            style={{ scale: 2.8 }}
          />

          <Egg
            startOffsetY={26}
            startOffsetX={52}
            animated
            imageIdx={5}
            style={{ scale: 2.3 }}
          />

          <Egg
            startOffsetY={31}
            startOffsetX={60}
            animated
            imageIdx={4}
            style={{ scale: 2.3 }}
          />

          <Egg
            startOffsetY={31}
            startOffsetX={67}
            animated
            imageIdx={3}
            style={{ scale: 2.5 }}
          />

          <Egg
            startOffsetY={29}
            startOffsetX={75}
            animated
            imageIdx={1}
            style={{ scale: 3 }}
          />
        </BrowserView>

        <MobileView
          style={{
            position: "absolute",
            minWidth: "100vw",
            width: "auto",
            height: "100dvh",
            transform: "scale(0.525) translateX(-10px)",
            aspectRatio: 16 / 9,
            clipPath:
              "polygon(0 0, 100% 0, 100% 50%, 80% 67%, 70% 68%, 60% 68%, 40% 68%, 0 64%)",
          }}
        >
          <Egg
            startOffsetY={27}
            startOffsetX={93}
            animated
            imageIdx={0}
            style={{ scale: 2.5 }}
          />
          <Egg
            startOffsetY={27}
            startOffsetX={84}
            animated
            imageIdx={2}
            style={{ scale: 2.8 }}
          />

          <Egg
            startOffsetY={26}
            startOffsetX={52}
            animated
            imageIdx={5}
            style={{ scale: 2.3 }}
          />

          <Egg
            startOffsetY={31}
            startOffsetX={60}
            animated
            imageIdx={4}
            style={{ scale: 2.3 }}
          />

          <Egg
            startOffsetY={31}
            startOffsetX={67}
            animated
            imageIdx={3}
            style={{ scale: 2.5 }}
          />

          <Egg
            startOffsetY={29}
            startOffsetX={75}
            animated
            imageIdx={1}
            style={{ scale: 3 }}
          />

     
        </MobileView>
      </div>

      {/* {eggs.map(({id, startOffsetX}) => (
				<Egg key={id} direction="right" startOffsetY={70} startOffsetX={startOffsetX} />
			))} */}
      {/* {eggs2.map(({id, startOffsetX, imageIdx}) => (
				<Egg
					key={id}
					startOffsetY={45}
					startOffsetX={startOffsetX}
					animated
					imageIdx={imageIdx}
				/>
			))} */}
      {/* {eggs3.map(({id, startOffsetX}) => (
				<Egg key={id} direction="left" startOffsetY={15} startOffsetX={startOffsetX} />
			))} */}

      <Button
        style={{ position: "absolute", top: "2dvh", left: "2dvh" }}
        onClick={() => window.open("https://t.me/qeggsbot")}
      >
        PLAY NOW
      </Button>

      <Button
        style={{ position: "absolute", top: "2dvh", right: "2dvh" }}
        onClick={() => setRoadmap(true)}
      >
        Road map
      </Button>

      {/* {timeToStart?.days && (
				<h2 style={{position: 'absolute', bottom: '2vh', left: '2vh'}}>
					Time to start -{' '}
					{`${timeToStart?.days} Days ${timeToStart?.hours} Hours ${timeToStart?.minutes} Minutes ${timeToStart?.seconds} Seconds`}
				</h2>
			)} */}

      <div
        style={{
          position: "absolute",
          bottom: "3vh",
          right: "4vh",
          display: "flex",
          gap: "20px",

          background: "rgb(20, 20, 20)",
          padding: "7px 12px",
          borderRadius: "10px",
          border: "3px solid rgb(41, 32, 53)",
        }}
      >
        {/* <FaFire onClick={() => {}} size={'35px'} color="white" /> */}
        <FaTelegramPlane
          onClick={() => window.open("https://t.me/Q_Egg")}
          size={"35px"}
          color="white"
        />
        <FaXTwitter
          onClick={() => window.open("https://twitter.com/EggsQuantu58870")}
          size={"35px"}
          color="white"
        />
      </div>
    </div>
  );
}

export default AppWeb;
