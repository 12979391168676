import React from "react";
import "./Loading.css";
import { motion } from "framer-motion";

import beatenEgg from "../../assets/img/beaten.png";
import hammer from "../../assets/img/hammer.png";

const Loading = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ y: "-50vh", opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      <div className={"loading"}>
        <div className={"loading_eggs"}>
          <span className={"loading_title"}>Loading...</span>
          <span className="loading-age">6+</span>

        </div>

      </div>
      
    </motion.div>
  );
};
export default Loading;
