import { React } from "react";
import "./Partners.css";
import AssetsIMG from "../../assets/img";
import Header from "../../components/Header/Header";
import Menu from "../../components/Menu/Menu";
import { motion } from "framer-motion";
const {
  itemBG,
  normalEgg,
  CloseIcon,
  tapOrNot,
  tonAllNews,
  tonNews,
  cryptoEpoch,
  profitHunters,
} = AssetsIMG;
const Partners = () => {
  const partnersList = [
    {
      name: "Profit hunters",
      link: "https://t.me/+hZ1FL_vlMGoxYjUy",
      logo: profitHunters,
    },
    {
      name: "All TON News",
      link: "https://t.me/ruatnews",
      logo: tonAllNews,
    },

    {
      name: "Ton News chain",
      link: "https://t.me/Ton_news_chain",
      logo: tonNews,
    },
    {
      name: "Tap or not Tap",
      link: "https://t.me/tapornottap",
      logo: tapOrNot,
    }
  ];

  return (
    <div className="partners">
      <Header name="PARTNERSHIP" />
      <motion.div
        className="partners-body"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{ overflowY: "scroll" }}
      >
        <motion.div
          className="partners-block"
          initial={{ opacity: 0, x: "-10vw" }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="partners-block-title">Communities</div>
          <div className="partners-block-list border-gradient">
            {partnersList &&
              partnersList.map((partner, index) => (
                <div key={index} className="partners-block-list-el">
                  <a href={partner.link} className="partners-block-logo">
                    <img src={partner.logo} alt="LOGO" />
                  </a>
                  <div className="partners-block-name"> {partner.name}</div>
                </div>
              ))}
          </div>
        </motion.div>
      </motion.div>
      <Menu active={"partners"} />
    </div>
  );
};

export default Partners;
