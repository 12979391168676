import "./TelegramReward.css";
import { motion } from "framer-motion";
import AssetsIMG from "../../assets/img";
import UserContext from "../../userContext";
import React, { useState, useEffect, useContext } from "react";

const TelegramReward = ({ closeView }) => {
  const [closed, setClosed] = useState(false);
  const [ourModalData, setOurModalData] = useState(null);
  const { user, userRewards, ws } = useContext(UserContext);
  const [visibleWrapper, setVisibleWrapper] = useState(false);
  const [visibleOurModal, setVisibleOurModal] = useState(false);
  const [partnerModalData, setPartnerModalData] = useState(null);
  const [visiblePartnerModal, setVisiblePartnerModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const {
    OTLogo,
    linkIcon,
    tapOrNot,
    qeggsLogo,
    proTonNews,
    loadingGIF,
    qeggsNftLogo,
    profitHunters,
  } = AssetsIMG;

  const checkRewardStatus = (rewardName) => {
    for (let i = 0; i < userRewards?.length; i++) {
      if (userRewards[i].reward_name == rewardName) {
        if (userRewards[i].claimed) return 0;
        if (userRewards[i].completed) return 1;
        return 2;
      }
    }
  };

  const partnersList = [
    {
      id: "TONT",
      logo: tapOrNot,
      name: "Tap or not Tap",
      link: "https://t.me/tapornottap",
    },
    {
      id: "PH",
      logo: profitHunters,
      name: "Profit Hunters",
      link: "https://t.me/PH_loans",
    },

    {
      id: "PTN",
      logo: proTonNews,
      name: "Pro Ton News",
      link: "https://t.me/Pro_T_r_o_n_news",
    }
  ];

  const oursList = [
    {
      id: "QS",
      logo: qeggsLogo,
      name: "Quantum Eggs",
      link: "https://t.me/qeggspace",
    },
    {
      id: "QE",
      logo: qeggsNftLogo,
      name: "Quantum Eggs NFT",
      link: "https://t.me/quantumeggs",
    },
    {
      id: "OT",
      logo: OTLogo,
      name: "OVERHEARD TON",
      link: "https://t.me/OverheardinTON",
    }
  ];

  setTimeout(() => setVisibleWrapper(true), 400);

  useEffect(
    () =>
      ws.send(
        JSON.stringify({ type: "getUserRewards", userId: user?.user_id })
      ),
    [user]
  );

  return (
    <div
      className={
        closed ? "telegram-reward reward-close-anim" : "telegram-reward"
      }
    >
      {visibleWrapper && (
        <div
          className="telegram-reward-wrapper"
          style={{ overflowX: "hidden", overflowY: "scroll" }}
        >
          
          <motion.div
            initial={{ y: "-15vh" }}
            animate={{ y: 0 }}
            transition={{ duration: 0.25 }}
            className="reward-close"
            onClick={() => {
              setClosed(true);
              setVisibleWrapper(false);
              setTimeout(() => {
                closeView();
              }, 400);
            }}
          >
            Back
          </motion.div>
          <motion.div
            initial={{ x: "-35vw" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.25 }}
            className="telegram-reward-title"
          >
            TELEGRAM TASKS
          </motion.div>
          <div className="telegram-block">
            <motion.div
              initial={{ x: "35vw" }}
              animate={{ x: 0 }}
              transition={{ duration: 0.25 }}
              className="telegram-block-title"
            >
              PARTNERS
            </motion.div>

            <div
              className="telegram-block-tasks"
              style={{ overflowY: "scroll", overflowX: "hidden" }}
            >
              {partnersList &&
                partnersList.map((partner, index) => (
                  <motion.div
                    key={index}
                    className={
                      visiblePartnerModal &&
                      partnerModalData.name == partner.name
                        ? "telegram-task active-partner"
                        : "telegram-task"
                    }
                    initial={{ x: "35vw" }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.25 }}
                    onClick={() => {
                      setPartnerModalData(partner);
                      setLoading(false);
                      setVisiblePartnerModal(!visiblePartnerModal);
                    }}
                  >
                    <div
                      className={
                        visiblePartnerModal &&
                        partnerModalData.name == partner.name
                          ? "telegram-task-logo telegram-active-logo"
                          : "telegram-task-logo"
                      }
                    >
                      <img src={partner.logo} alt="LOGO" />
                    </div>
                  </motion.div>
                ))}

              <div
                className={
                  visiblePartnerModal
                    ? "telegram-task-info active-telegram-task-info"
                    : "telegram-task-info"
                }
              >
                <div className="telegram-task-info-title">
                  {partnerModalData?.name}
                </div>
                <div className="telegram-task-info-link">
                  <a
                    href={partnerModalData?.link}
                    onClick={() => {
                      ws.send(
                        JSON.stringify({
                          type: "setUserReward",
                          userId: user?.user_id,
                          rewardName: `sub_${partnerModalData?.id}`,
                        })
                      );
                      {
                        checkRewardStatus(`sub_${partnerModalData?.id}`) &&
                          setLoading(true);
                      }
                    }}
                  >
                    <img src={linkIcon} alt="link" />
                  </a>
                </div>
                <ul>
                  <li>
                    Subscribe to the channel
                    <div className="telegram-task-info-award ">
                      {loading &&
                        checkRewardStatus(`sub_${partnerModalData?.id}`) !=
                          0 && (
                          <img
                            className="telegram-task-info-award-loading loading-animation"
                            src={loadingGIF}
                            alt="GIF"
                          />
                        )}

                      {checkRewardStatus(`sub_${partnerModalData?.id}`) != 0 ? (
                        checkRewardStatus(`sub_${partnerModalData?.id}`) ===
                        1 ? (
                          <span
                            className="telegram-task-award-active"
                            onClick={() => {
                              ws.send(
                                JSON.stringify({
                                  type: "claimUserReward",
                                  userId: user?.user_id,
                                  rewardName: `sub_${partnerModalData?.id}`,
                                })
                              );
                              setLoading(true);
                            }}
                          >
                            {loading ? "WAIT " : "5000 EGGS"}
                          </span>
                        ) : (
                          <span className="telegram-task-award-inactive">
                            5000 EGGS
                          </span>
                        )
                      ) : (
                        <span className="telegram-task-award-inactive">
                          COMPLETED
                        </span>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="telegram-block">
            <motion.div
              initial={{ x: "-35vw" }}
              animate={{ x: 0 }}
              transition={{ duration: 0.25 }}
              className="telegram-block-title-odd"
            >
              OUR SOCIAL
            </motion.div>

            <div
              className="telegram-block-tasks"
              style={{ overflowY: "scroll", overflowX: "hidden" }}
            >
              {oursList &&
                oursList.map((partner, index) => (
                  <motion.div
                    key={index}
                    className={
                      visibleOurModal && ourModalData.name == partner.name
                        ? "telegram-task-odd active-partner-odd"
                        : "telegram-task-odd"
                    }
                    initial={{ x: "-35vw" }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.25 }}
                    onClick={() => {
                      setOurModalData(partner);
                      setLoading(false);
                      setVisibleOurModal(!visibleOurModal);
                    }}
                  >
                    <div
                      className={
                        visibleOurModal && ourModalData.name == partner.name
                          ? "telegram-task-logo telegram-active-logo-odd"
                          : "telegram-task-logo"
                      }
                    >
                      <img src={partner.logo} alt="LOGO" />
                    </div>
                  </motion.div>
                ))}

              <div
                className={
                  visibleOurModal
                    ? "telegram-task-info-odd active-telegram-task-info-odd"
                    : "telegram-task-info-odd"
                }
              >
                <div className="telegram-task-info-title">
                  {ourModalData?.name}
                </div>
                <div className="telegram-task-info-link">
                  <a
                    href={ourModalData?.link}
                    onClick={() => {
                      ws.send(
                        JSON.stringify({
                          type: "setUserReward",
                          userId: user?.user_id,
                          rewardName: `sub_${ourModalData?.id}`,
                        })
                      );
                      {
                        checkRewardStatus(`sub_${ourModalData?.id}`) &&
                          setLoading(true);
                      }
                    }}
                  >
                    <img src={linkIcon} alt="link" />
                  </a>
                </div>
                <ul>
                  <li>
                    Subscribe to the channel
                    <div className="telegram-task-info-award ">
                      {loading &&
                        checkRewardStatus(`sub_${ourModalData?.id}`) != 0 && (
                          <img
                            className="telegram-task-info-award-loading loading-animation"
                            src={loadingGIF}
                            alt="GIF"
                          />
                        )}

                      {checkRewardStatus(`sub_${ourModalData?.id}`) != 0 ? (
                        checkRewardStatus(`sub_${ourModalData?.id}`) === 1 ? (
                          <span
                            className="telegram-task-award-active"
                            onClick={() => {
                              ws.send(
                                JSON.stringify({
                                  type: "claimUserReward",
                                  userId: user?.user_id,
                                  rewardName: `sub_${ourModalData?.id}`,
                                })
                              );
                              setLoading(true);
                            }}
                          >
                            {loading ? "WAIT " : "5000 EGGS"}
                          </span>
                        ) : (
                          <span className="telegram-task-award-inactive">
                            5000 EGGS
                          </span>
                        )
                      ) : (
                        <span className="telegram-task-award-inactive">
                          COMPLETED
                        </span>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TelegramReward;
