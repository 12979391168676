import { TonConnectUI } from "@tonconnect/ui";

const tonConnectUI = new TonConnectUI({
  manifestUrl: "https://qeggs.space/tonconnect-manifest.json",
  walletsListConfiguration: {
    app_name: "tonkeeper",
    name: "Tonkeeper",
    image: "https://tonkeeper.com/assets/tonconnect-icon.png",
    tondns: "tonkeeper.ton",
    about_url: "https://tonkeeper.com",
    universal_url: "https://app.tonkeeper.com/ton-connect",
    bridge: [
      {
        type: "sse",
        url: "https://bridge.tonapi.io/bridge",
      },
      {
        type: "js",
        key: "tonkeeper",
      },
    ],
    platforms: [
      "ios",
      "android",
      "chrome",
      "firefox",
      "safari",
      "windows",
      "macos",
      "linux",
    ],
  },
});
tonConnectUI.uiOptions = {
  actionsConfiguration: {
    returnStrategy: "back",
  },
};
export default tonConnectUI;
