const tg = window.Telegram.WebApp;

export function useTelegram(){
    const onClose = () => {
        tg.close()
    }

    const onToggleButton = () => {
        if (tg.MainButton.isVisible) {
            tg.MainButton.hide();
        }
        else {
            tg.MainButton.show();
        }
    }
    return { 
        tg,
        onToggleButton,
        onClose,
        userTel: tg.initDataUnsafe?.user,
        tgVibro: tg.HapticFeedback
    }
} 

export default useTelegram