import React, { useContext } from "react";
import { useEffect, useState } from "react";
import "./Preview.css";
import Loading from "../Loading/Loading";
import backEgg from "../../assets/img/back.png";
import backgroundGate from "../../assets/img/backgroundGif.gif";

import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import UserContext from "../../userContext";

const Preview = () => {
  const {user, ws} = useContext(UserContext)
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const images = [backEgg, backgroundGate];

    Promise.all(
      images.map((image) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = image;
          img.onload = resolve;
          img.onerror = reject;
        });
      })
    )
      .then(() => {
        new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
          setLoading(false);
        });
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      });
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
      <div className={"preview"}>
        <img className={"preview_img"} src={backgroundGate} alt="GIF" />

        <div className={"preview_title"}>
          Quantum <span>eggs</span>
        </div>
        <div className={"preview_text"}>
          <h1>The legend of Galaxy Eggchasers</h1>
          <h2>Began thousands of years ago...</h2>
          <p>
            When the evil Glorgs from the TG galaxy, quantum egg grabbers,
            decided to seize the legendary Quantum eggs.
          </p>
          <p>
            The glorgs use quantum technology, strength and cunning to get to
            the Quantum eggs
          </p>
          <p>
            Along the way, they continue to hit worlds in search of quantum eggs
            with unknown powers
          </p>
          <p>
            Quantum eggs took refuge on the mysterious planet TON, becoming the
            last hope in the universe.
          </p>
        </div>
        <div className={"preview_egg"}>
          <img src={backEgg} alt="EGG" />
        </div>

        <div          className={"preview_button"}

          onClick={async () => {
            await ws?.send(JSON.stringify({ type: "addSession", userId: user?.user_id, date: Date() }))
            navigate("/home");
          }}
        >
      START
        </div>
      </div>
  );
};

export default Preview;
