import styles from './Button.module.css'

function Button({children, type = 1, ...props}) {
	return (
		<button {...props} className={`${styles.button} ${styles['type' + type]}`}>
			<p>{children}</p>
		</button>
	)
}

export default Button
