import { React, useContext, useEffect, useState } from "react";
import "./Mobile.css";
import QRCode from 'react-qr-code';


const Mobile = () => {
    return (
        <div className="mobile">
             <div>
      <QRCode value="https:t.me/qeggsbot" />
    </div>
            PLAY ON MOBILE...
            
        </div>
    )
}

export default Mobile;