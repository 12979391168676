import { React, useState, useContext } from "react"
import "./Menu.css"
import AssetsIMG from "../../assets/img"
import { useNavigate } from "react-router-dom"
import UserContext from "../../userContext"
import vibrateDevice from "../../hooks/vibrateDevice"
const { eggsIcon, boostIcon, homeIcon, friendsIcon, rewardIcon, collab } = AssetsIMG

const Menu = ({ active }) => {
  const [activeButton, setActiveButton] = useState(active)
  const navigate = useNavigate()
  const {haptic} = useContext(UserContext)
  const handleMenuButtonClick = (buttonName) => {
    setActiveButton(buttonName)
  }

  return (
    <div className="Menu">
      <div className="home-menu">
        <button
          onClick={() => {
            handleMenuButtonClick("eggs")
            vibrateDevice("soft")
            navigate("/eggs")
          }}
          className={activeButton === "eggs" ? "active" : "unActive"}
        >
          <img src={eggsIcon} alt="eggs" />
          <div className="menu-block-title">Eggs</div>
        </button>
        <button
          onClick={() => {
            handleMenuButtonClick("boost")
            vibrateDevice("soft")
            navigate("/boost")
          }}
          className={activeButton === "boost" ? "active" : "unActive"}
        >
          <img src={boostIcon} alt="Boost" />
          <div className="menu-block-title">Boost</div>
        </button>
        <button
          onClick={() => {
            handleMenuButtonClick("home")
            vibrateDevice("soft")
            navigate("/home")
          }}
          className={activeButton === "home" ? "active" : "unActive"}
        >
          <img src={homeIcon} alt="Home" />
          <div className="menu-block-title">Home</div>
        </button>
        <button
          onClick={() => {
            handleMenuButtonClick("friends")
            vibrateDevice("soft")

            navigate("/friends")
          }}
          className={activeButton === "friends" ? "active" : "unActive"}
        >
          <img src={friendsIcon} alt="Friends" />
          <div className="menu-block-title">Friends</div>
        </button>
        <button
          onClick={() => {
            handleMenuButtonClick("rewards")
            vibrateDevice("soft")

            navigate("/rewards")
          }}
          className={activeButton === "rewards" ? "active" : "unActive"}
        >
          <img src={rewardIcon} alt="Reward" />
          <div className="menu-block-title">Reward</div>
        </button>
       
      </div>
    </div>
  )
}
export default Menu
