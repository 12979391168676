import React, { useState, useEffect, useContext } from "react";
import "./Boost.css";
import { motion } from "framer-motion";
import Loading from "../Loading/Loading";
import Menu from "../../components/Menu/Menu";
import Header from "../../components/Header/Header";
import AssetsIMG from "../../assets/img";
import UserContext from "../../userContext";
/*import { beginCell } from '@ton/ton'*/

//import { beginCell } from "@ton/ton";

const { tonIcon, backEgg, boostRocket } = AssetsIMG;

const images = [tonIcon, backEgg, boostRocket];

const Boost = () => {
  const [loading, setLoading] = useState(true);
  const { ws, boosts, user, connectWS, tonConnectUI, tonWeb } =
    useContext(UserContext);
  const [freeTapStatus, setFreeTapStatus] = useState(null);
  const [freeEnergyStatus, setFreeEnergyStatus] = useState(null);
  const [transactRes, setTransactRes] = useState(null);

  const sendTon = async (amount, type) => {
    /*const body = beginCell()
      .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
      .storeStringTail("Quantum EGG INIT") // write our text comment
      .endCell();
    */
    try {
      let transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 60,
        messages: [
          {
            address: "UQD_Im5FhmUtsDtl_VnFpYNM-RLGOevS6A1408TNqI0Ax4B0",
            amount: amount * 1_000_000_000,
            /*payload: body.toBoc().toString("base64")*/
          },
        ],
      };

      if (ws?.readyState === 1) {
       const result = await tonConnectUI.sendTransaction(transaction);
        if (result) { //result
          if (ws?.readyState !== 1) {
            await connect();
            ws?.send(
              JSON.stringify({
                type: type,
                userId: user?.user_id,
                boc: result.boc,
                wallet: tonConnectUI?.account?.address,
              })
            );
          } else {
            ws?.send(
              JSON.stringify({
                type: type,
                userId: user?.user_id,
                boc: result.boc,
                wallet: tonConnectUI?.account?.address,
              })
            );
          }
        }
      }
    } catch (e) {
      console.error("Ошибка", e);
    }
  };

  useEffect(() => {
    setFreeTapStatus(user?.free_tap);
    setFreeEnergyStatus(user?.free_energy);
  }, [user]);

  const getBoosts = async () => {
    try {
      ws?.send(JSON.stringify({ type: "getBoosts" }));
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  };
  async function connect() {
    await connectWS();
  }
  useEffect(() => {
      if (ws?.readyState !== 1) {
      connect();
    }

    getBoosts();

    Promise.all(
      images.map((el) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = el;
          img.onload = resolve;
          img.onerror = reject;
        });
      })
    )

      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      });
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className={"boost"} style={{ overflowY: "scroll" }}>
      <Header name="BOOST" />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={"boost-body"}
        style={{ overflowY: "scroll" }}
      >
        <motion.div className="boost-rocket">
          <img src={boostRocket} alt="boost" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: "10vw" }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="boost-text"
        >
          <div className="boost-text-title">
            You can buy a boost to achieve your goals even faster!
          </div>
          <div className="boost-text-descr">Choose what you need most!</div>
        </motion.div>

        <div className="boost-items">
          {boosts &&
            boosts.map((boost, index) => (
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.15 * (index + 1) }}
                className="boost-items-el"
              >
                <div key={index} className="boost-items-el-info">
                  {boost.type === "freeEnergyFull" ||
                  boost.type === "off_recovery" ? (
                    ""
                  ) : (
                    <div className="boost-items-el-rate">x {boost.rate}</div>
                  )}

                  <div className="boost-items-el-name">{boost.name}</div>
                  {boost.type === "freeEnergyFull" ||
                  boost.type === "off_recovery" ? (
                    ""
                  ) : boost.type === "auto_tap" ? (
                    <div className="boost-items-el-duration">∞</div>
                  ) : boost.type === "freeTap" ? (
                    <div className="boost-items-el-duration">
                      {boost.duration} m
                    </div>
                  ) : (
                    <div className="boost-items-el-duration">
                      {boost.duration} h
                    </div>
                  )}
                </div>

                {boost.type === "freeEnergyFull" || boost.type === "freeTap" ? (
                  <div
                    className={
                      boost.type === "freeEnergyFull"
                        ? user?.free_energy !== 0
                          ? "boost-items-el-buy"
                          : "boost-items-el-buy-inactive"
                        : user?.free_tap !== 0
                        ? "boost-items-el-buy"
                        : "boost-items-el-buy-inactive"
                    }
                    onClick={
                      boost.type === "freeEnergyFull"
                        ? async () => {
                            await ws?.send(
                              JSON.stringify({
                                type: "setFreeEnergyBoost",
                                userId: user?.user_id,
                              })
                            );
                          }
                        : async () => {
                            await ws?.send(
                              JSON.stringify({
                                type: "setFreeTapBoost",
                                userId: user?.user_id,
                              })
                            );
                          }
                    }
                  >
                    <div
                      className="boost-items-el-cost-egg"
                      style={{ width: "100%" }}
                    >
                      <span>
                        {boost.type === "freeEnergyFull" && user?.free_energy}{" "}
                        {boost.type === "freeTap" && user?.free_tap} Free
                      </span>
                      <img src={backEgg} alt="EGG" />
                    </div>
                  </div>
                ) : (
                  <div className="boost-items-el-buy">
                    <div
                      className="boost-items-el-cost-ton"
                      style={{ width: "100%" }}
                      onClick={async () => {
                        if (tonConnectUI.connected) {
                          boost.type === "auto_tap" &&
                            (await sendTon(boost.ton_cost, "setAutoClicker"));

                          boost.type === "tap" &&
                            (await sendTon(boost.ton_cost, "setDoubleTap"));

                          boost.type === "off_recovery" &&
                            (await sendTon(boost.ton_cost, "setOffRecovery"));
                        } else {
                          await tonConnectUI.openSingleWalletModal("tonkeeper");
                        }
                      }}
                    >
                      <span>{boost.ton_cost}</span>
                      <img src={tonIcon} alt="TON" />
                    </div>
                  </div>
                )}
              </motion.div>
            ))}
        </div>
      </motion.div>
      <Menu active={"boost"} />
    </div>
  );
};

export default Boost;
